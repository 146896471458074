<template>
    <div>
        <div class="select border d-flex justify-content-between" @click="is_rotate = !is_rotate">
            <div>{{ name }}</div>
            <div><img :class="{ 'durationIs': is_rotate }" :src="require('@/assets/images/icons/ChevronUp.svg')"></div>
        </div>
        <slot v-if="is_rotate"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: 'Нет данных' 
            },
        },
        data() {
            return {
                is_rotate: true
            }
        },
    }
</script>

<style lang="scss" scoped>
.durationIs {
  animation-duration: 750ms;
  transform: rotate(3.142rad);
}
.select {
    margin: 5px;
    padding: 5px;
    background: #0b86be;
    color: #ffff;
}
</style>